// Generated by Framer (575e68f)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFX = withFX(Image);

const cycleOrder = ["R83WiGMja"];

const serializationHash = "framer-TcAmy"

const variantClassNames = {R83WiGMja: "framer-v-919d22"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 300, type: "spring"}

const animation1 = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition1, x: 0, y: 0}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "R83WiGMja", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><ImageWithFX {...restProps} {...gestureHandlers} __framer__animate={{transition: transition1}} __framer__animateOnce={false} __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill", intrinsicHeight: 1024.5, intrinsicWidth: 501.5, pixelHeight: 2049, pixelWidth: 1003, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/jd9mX8eNqRghn7KyixE736ng.png?scale-down-to=2048", srcSet: "https://framerusercontent.com/images/jd9mX8eNqRghn7KyixE736ng.png?scale-down-to=2048 1002w,https://framerusercontent.com/images/jd9mX8eNqRghn7KyixE736ng.png 1003w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-919d22", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"R83WiGMja"} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-TcAmy.framer-1uqfrnq, .framer-TcAmy .framer-1uqfrnq { display: block; }", ".framer-TcAmy.framer-919d22 { height: 545px; overflow: visible; position: relative; width: 267px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 545.5
 * @framerIntrinsicWidth 267
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjB5Nwkl8j: React.ComponentType<Props> = withCSS(Component, css, "framer-TcAmy") as typeof Component;
export default FramerjB5Nwkl8j;

FramerjB5Nwkl8j.displayName = "Phone Mockup";

FramerjB5Nwkl8j.defaultProps = {height: 545.5, width: 267};

addFonts(FramerjB5Nwkl8j, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})